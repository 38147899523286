import {
    Table,
    Card,
    CardHeader,
    Modal,
    Button,
    ModalBody,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Spinner,
    ButtonGroup,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { BiEdit } from "react-icons/bi";
import "../../assets/css/styles.css";
import Paginations from "components/Pagination/Paginations";
import { getUser } from "actions/Auth";
import { addNewtransaction } from "actions/Transactions";
import { getAllTransactions } from "actions/Transactions";
import DatePicker from "react-datepicker";
import { getDailyExpenceSum } from "actions/Transactions";
import { editTransaction } from "actions/Transactions";
import { getTransactionSummary } from "actions/Transactions";
import { Pie } from "react-chartjs-2";
import { getIncomeAndExpenses } from "actions/Transactions";
import { getTeacherTransactionDetails } from "actions/Transactions";
import { findAllClassFeesBalance } from "actions/Transactions";

const Transactions = () => {
    const TransactionReason = {
        ADMIN: "Admin Withrawal",
        OTHER: "Other",
    };
    const [rSelected, setRSelected] = useState(null);
    const [selectedTransactionReason, setSelectedTransactionReason] = useState('');
    const [user, setUser] = useState(null);
    const [isError, setIsError] = useState(false);
    const [allTransactions, setAllTransactiona] = useState(null);
    const [isAllTransactions, setIsAllTransactions] = useState(false);
    const [isLoadingTransactions, setLoadingTransactions] = useState(true);
    const [monthDropdownOpen, setMonthDropdownOpen] = useState(false);
    const [months, setMonths] = useState([]);
    const [balance, setBalance] = useState(null);
    const [classFeesBalance, setClassFeesBalance] = useState(null);

    const [monthSummaryDropdownOpen, setMonthSummaryDropdownOpen] = useState(false);
    const [allTransactionSummary, setAllTransactionSummary] = useState(null);
    const [isAllTransactionSummary, setIsAllTransactionSummary] = useState(false);
    const [isLoadingTransactionSummary, setLoadingTransactionSummary] = useState(true);
    const [summaryPageCount, setSummaryPageCount] = useState(0);
    const [currentSummaryStart, setCurrentSummaryStart] = useState(0);
    const [currentSummaryPage, setCurrentSummaryPage] = useState(1);
    const [summaryItemsPerPge, setSummaryItemsPerPage] = useState(5);

    const [monthTeacherDropdownOpen, setMonthTeacherDropdownOpen] = useState(false);
    const [allTeacherPayments, setAllTeacherPayments] = useState(null);
    const [isAllTeacherPayments, setIsAllTeacherPayments] = useState(false);
    const [isLoadingTeacherPayments, setLoadingTeacherPayments] = useState(true);

    const [isSuccess, setSuccess] = useState(false);
    const [isFailed, setFailed] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [editModal, setEditmodal] = useState(false);
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
    const [isInsertButtonDisabled, setinsertButtonDisabled] = useState(false);
    const [isSuccessMessage, setSuccessMessage] = useState("");
    const [isErrorMessage, setErrorMessage] = useState("");
    const [isEditedInput, setEditedInput] = useState(false);
    const [isDisabledEditButton, setDisabledEditButton] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPge, SetItemsPerPage] = useState(5);
    const [skip, setSkip] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [currentStart, setCurrentStart] = useState(0);

    const [currentTeacherPage, setCurrentTeacherPage] = useState(1);
    const [itemsPerTeacherPge, SetItemsPerTeacherPage] = useState(5);
    const [skipTeacher, setTeacherSkip] = useState(0);
    const [pageTeacherCount, setPageTeacherCount] = useState(0);
    const [currentTeacherStart, setCurrentTeacherStart] = useState(0);


    const [initialTransaction, setInitialTransaction] = useState({
        id: null,
        description: "",
        amount: Number,
    });

    const [isData, setIsData] = useState({
        transactionType: "",
        transactionReason: "",
        description: "",
        amount: Number,
    });

    const [errorMsg, setErrorMsg] = useState({
        transactionType: "",
        transactionReason: "",
        description: "",
        amount: "",
    });

    const [isEditError, setEditError] = useState({
        description: "",
        amount: "",
    });

    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const newMonth = year + "-" + month;
    const newDate = year + "-" + month + "-" + day;
    const [selectedMonth, setSelectedMonth] = useState(newMonth);
    const [selectedDate, setSelectedDate] = useState(null);
    const [isMonth, setMonth] = useState(true);
    const [isDate, setDate] = useState(false);

    const [selectedSummaryMonth, setSelectedSummaryMonth] = useState(newMonth);
    const [isSummaryMonth, setSummaryMonth] = useState(true);
    const [data, setData] = useState({});

    const [selectedTeacherMonth, setSelectedTeacherMonth] = useState(newMonth);
    const [selectedTeacherDate, setSelectedTeacherDate] = useState(null);
    const [isTeacherMonth, setTeacherMonth] = useState(true);
    const [isTeacherDate, setTeacherDate] = useState(false);

    const getTransactionDetails = async (date, limit) => {
        try {
            const response = await getAllTransactions(date, limit, itemsPerPge);
            if (response.data.length > 0) {
                setAllTransactiona(response.data);
                setPageCount(response.pageCount);
                setIsAllTransactions(true);
                setLoadingTransactions(false);
            } else {
                setPageCount(0);
                setIsAllTransactions(false);
                setLoadingTransactions(false);
            }
        } catch (error) {
            setIsAllTransactions(false);
            setLoadingTransactions(false);
        }
    }

    const getTransactionBalance = async (date) => {
        try {
            const response = await getDailyExpenceSum(date);
            if (response.balance !== null) {
                setBalance(response.balance);
            } else {
                setBalance(null);
            }
        } catch (error) {
            setFailed(true);
            setErrorMessage(error.response.message);
        }
    }

    const getClassFeesBalance = async (date) => {
        try {
            const response = await findAllClassFeesBalance(date);
            if (response.balance !== null) {
                setClassFeesBalance(response.balance);
            } else {
                setClassFeesBalance(null);
            }
        } catch (error) {
            setFailed(true);
            setErrorMessage(error.response.message);
        }
    }

    const getExpenseSummaryDetails = async (date, limit) => {
        try {
            const response = await getTransactionSummary(date, limit, summaryItemsPerPge);
            if (response.data.length > 0) {
                setAllTransactionSummary(response.data);
                setSummaryPageCount(response.pageCount);
                setIsAllTransactionSummary(true);
                setLoadingTransactionSummary(false);
            } else {
                setSummaryPageCount(0);
                setIsAllTransactionSummary(false);
                setLoadingTransactionSummary(false);
            }
        } catch (error) {
            setIsAllTransactionSummary(false);
            setLoadingTransactionSummary(false);
        }
    }

    const getGraphData = async (date) => {
        const response = await getIncomeAndExpenses(date);
        setData({
            labels: [
                'Income',
                'Expenses',
            ],
            datasets: [{
                label: 'Expenses and Income',
                data: [response.income, (((response.expenses) * -1).toFixed(2))],
                backgroundColor: [
                    '#F7D060',
                    '#FF6D60',
                ],
                hoverOffset: 4
            }]
        })
    }

    const getTeacherPaymentDetails = async (date, limit) => {
        try {
            const response = await getTeacherTransactionDetails(date, limit, itemsPerTeacherPge);
            if (response.data.length > 0) {
                setAllTeacherPayments(response.data);
                setPageTeacherCount(response.pageCount);
                setIsAllTeacherPayments(true);
                setLoadingTeacherPayments(false);
            } else {
                setPageTeacherCount(0);
                setIsAllTeacherPayments(false);
                setLoadingTeacherPayments(false);
            }
        } catch (error) {
            setIsAllTeacherPayments(false);
            setLoadingTeacherPayments(false);
        }
    }

    const getMonthList = async () => {
        const startDate = new Date();
        const endDate = new Date();
        startDate.setMonth(startDate.getMonth() - (12 - 1));
        const startMonth = startDate.getMonth();
        const endMonth = endDate.getMonth();
        const startYear = startDate.getFullYear();
        const endYear = endDate.getFullYear();
        const months = [];

        for (let year = startYear; year <= endYear; year++) {
            const monthStart = year === startYear ? startMonth : 0;
            const monthEnd = year === endYear ? endMonth : 11;

            for (let month = monthStart; month <= monthEnd; month++) {
                const monthString = `${year}-${(month + 1)
                    .toString()
                    .padStart(2, "0")}`;
                months.push({
                    label: monthString,
                    month: month + 1,
                    year: year,
                });
            }
        }
        setMonths(months);
    }

    const fetchData = async () => {
        try {
            const user = await getUser();
            setUser(user.id);
            await getMonthList();
            await getTransactionDetails(selectedMonth, skip);
            await getTransactionBalance(selectedMonth);
            await getClassFeesBalance(selectedMonth);
            await getExpenseSummaryDetails(selectedMonth, skip);
            await getGraphData(selectedSummaryMonth);
            await getTeacherPaymentDetails(selectedMonth, skip)
        } catch (error) {
            setLoadingTransactions(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const filterBySearch = async (label) => {
        setMonth(true);
        setDate(false);
        setSelectedDate(null);
        setSelectedMonth(label);
        await getTransactionDetails(label, skip);
        setCurrentPage(skip + 1);
        await getTransactionBalance(label);
        await getClassFeesBalance(label);
    }

    const filterByDate = async (date) => {
        setDate(true);
        setMonth(false);
        setSelectedMonth(null);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const newDate = year + "-" + month + "-" + day;
        setSelectedDate(newDate);
        await getTransactionDetails(newDate, skip);
        await getTransactionBalance(newDate);
        await getClassFeesBalance(newDate);
        setCurrentStart(0);
        setCurrentPage(skip + 1);
    }
    const handlePagination = async (pageNumber) => {
        if (isMonth) {
            await getTransactionDetails(selectedMonth, pageNumber - 1);
        } else {
            await getTransactionDetails(selectedDate, pageNumber - 1);
        }
        setCurrentPage(pageNumber);
    }

    const filterBySummarySearch = async (label) => {
        setSummaryMonth(true);
        setSelectedSummaryMonth(label);
        await getExpenseSummaryDetails(label, skip);
        await getGraphData(label);
        setCurrentSummaryPage(skip + 1);
    }

    const handleSummaryPagination = async (pageNumber) => {
        await getExpenseSummaryDetails(selectedMonth, pageNumber - 1);
        setCurrentSummaryPage(pageNumber);
    }

    const filterByTeacherSearch = async (label) => {
        setTeacherMonth(true);
        setTeacherDate(false);
        setSelectedTeacherDate(null);
        setSelectedTeacherMonth(label);
        await getTeacherPaymentDetails(label, skip);
        setCurrentTeacherPage(skip + 1);
    }

    const filterByTeacherDate = async (date) => {
        setTeacherDate(true);
        setTeacherMonth(false);
        setSelectedTeacherMonth(null);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const newDate = year + "-" + month + "-" + day;
        setSelectedTeacherDate(newDate);
        await getTeacherPaymentDetails(newDate, skip);
        setCurrentTeacherStart(0);
        setCurrentTeacherPage(skip + 1);
    }

    const handleTeacherPagination = async (pageNumber) => {
        if (isTeacherMonth) {
            await getTeacherPaymentDetails(selectedTeacherMonth, pageNumber - 1);
        } else {
            await getTeacherPaymentDetails(selectedTeacherDate, pageNumber - 1);
        }
        setCurrentTeacherPage(pageNumber);
    }


    useEffect(() => {
    }, [isData]);

    const handleInputChange = (event) => {
        setErrorMsg({ ...errorMsg, [event.target.name]: "" });
        setIsData({ ...isData, [event.target.name]: event.target.value });
    };

    const formatDate = async (dateString) => {
        let date = new Date(dateString);
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let day = String(date.getDate()).padStart(2, '0');
        let hours = String(date.getHours()).padStart(2, '0');
        let minutes = String(date.getMinutes()).padStart(2, '0');
        let seconds = String(date.getSeconds()).padStart(2, '0');
        let milliseconds = String(date.getMilliseconds()).padStart(3, '0');
        let microseconds = "000";
        let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}${microseconds}`;
        return formattedDate;
    }

    const arrangeData = async (value, transactionType) => {
        const insertData = {}
        const date = new Date();
        const formatedDate = await formatDate(date);
        insertData.date = formatedDate;
        if (value.transactionReason === "Admin Withrawal") {
            insertData.description = "Admin transaction"
        } else {
            insertData.description = value.description;
        }
        if (transactionType === 1) {
            insertData.amount = -value.amount;
        }
        if (transactionType === 2) {
            insertData.amount = +(value.amount);
        }
        insertData.userId = +(user);
        if (isNaN(insertData.amount)) {
            setErrorMsg({ amount: 'Invalid amount: Amount must be a number' });
            return null
        } else {
            setIsError(false);
            return insertData;
        }
    };

    const insertNewTransaction = async () => {
        try {
            const insertData = await arrangeData(isData, rSelected);
            if (insertData !== null) {
                setinsertButtonDisabled(true);
                const body = JSON.stringify(insertData);
                const response = await addNewtransaction(body);
                if (response.success === true) {
                    setSuccessMessage(response.message);
                    setSuccess(true);
                    fetchData();
                    setEditmodal(false);
                    setIsData({
                        transactionType: "",
                        transactionReason: "",
                        description: "",
                        amount: "",
                    });
                    setSelectedTransactionReason('');
                    setRSelected(null);
                } else {
                    setFailed(true);
                    setErrorMessage(response.message);
                }
            }
        } catch (error) {
            setFailed(true);
            setErrorMessage(error.response.message);
        }
    };

    const handleEditClick = (transaction) => {
        setSelectedTransaction(transaction);
        setInitialTransaction(transaction);
        setEditmodal(true);
    };

    const isValidEditTransaction = (value) => {
        const errors = {};
        if (value.amount === null) {
            errors.amount = "Value must not be null.";
        }
        if (Object.keys(errors).length > 0) {
            setEditError(errors);
            setDisabledEditButton(false);
            return false;
        } else {
            return true;
        }
    };

    const editTransactionDetails = async () => {
        const editedData = {}
        const validityCheck = isValidEditTransaction(selectedTransaction);
        if (validityCheck === true) {
            setEditedInput(false);
            editedData.id = selectedTransaction.id;
            editedData.updated_by = +user;
            const date = new Date();
            const formatedDate = await formatDate(date);
            editedData.date = formatDate;
            if (initialTransaction.amount < 0) {
                editedData.amount = -selectedTransaction.amount;
            }
            editedData.amount = +selectedTransaction.amount;
            const body = JSON.stringify(editedData);
            const response = await editTransaction(body);
            if (response.success === true) {
                setSuccessMessage(response.message);
                setSuccess(true);
                fetchData();
                setEditmodal(false);
            } else {
                setFailed(true);
            }
        }
    };

    return (
        <>
            <SecondaryHeader />
            <div className="mt--5 container-fluid">
                <Modal
                    className="modal-dialog-centered modal-success"
                    isOpen={isSuccess}
                    toggle={() => setSuccess(false)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isSuccessMessage}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setSuccess(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </Modal>
                <Modal
                    className="modal-dialog modal-danger"
                    isOpen={isFailed}
                    toggle={() => setFailed(false)}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            {isErrorMessage}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => setFailed(false)}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                </Modal>
                <Card className="mb-4 shadow">
                    <CardHeader className="border-0">
                        <h3 className="mb-0">Add New Transaction</h3>
                    </CardHeader>
                    <Form className="ml-4 mb-4 mr-4">
                        <Row>
                            <Col md="12" lg="7">
                                <FormGroup>
                                    <ButtonGroup>
                                        <Button
                                            color="primary"
                                            outline
                                            onClick={() => setRSelected(1)}
                                            active={rSelected === 1}
                                        >
                                            Credit
                                        </Button>
                                        <Button
                                            color="primary"
                                            outline
                                            onClick={() => setRSelected(2)}
                                            active={rSelected === 2}
                                        >
                                            Debit
                                        </Button>
                                    </ButtonGroup>
                                    {isError.transaction && (
                                        <p className="text-danger">{isError.transaction}</p>
                                    )}
                                </FormGroup>
                                <Row style={{ justifyContent: "space-between" }}>
                                    <Col>
                                        <FormGroup>
                                            <Dropdown
                                                isOpen={statusDropdownOpen}
                                                toggle={() => setStatusDropdownOpen(!statusDropdownOpen)}
                                            >
                                                <DropdownToggle caret>
                                                    {selectedTransactionReason
                                                        ? selectedTransactionReason.status
                                                        : "Select a Transaction Type"}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.entries(TransactionReason).map(([key, value]) => (
                                                        <DropdownItem
                                                            key={value}
                                                            value={value}
                                                            onClick={() => [
                                                                setSelectedTransactionReason({
                                                                    ...selectedTransactionReason,
                                                                    status: value,
                                                                }),
                                                                setIsData({
                                                                    transactionReason: value
                                                                }),
                                                            ]}
                                                        >
                                                            {value}
                                                        </DropdownItem>
                                                    ))}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {selectedTransactionReason.status === "Other" ?
                                            <FormGroup>
                                                <Input
                                                    id="description"
                                                    placeholder="Description"
                                                    type="text"
                                                    value={isData.description}
                                                    onChange={handleInputChange}
                                                    name="description"
                                                />
                                                {isError.description && (
                                                    <p className="text-danger">{isError.description}</p>
                                                )}
                                            </FormGroup> : null
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Input
                                                id="amount"
                                                placeholder="Amount"
                                                type="number"
                                                value={isData.amount || ''}
                                                onChange={handleInputChange}
                                                min={0}
                                                name="amount"
                                            />
                                            {errorMsg.amount && (
                                                <p className="text-danger">{errorMsg.amount}</p>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Button
                                    color="primary"
                                    type="button"
                                    onClick={() => {
                                        insertNewTransaction();
                                    }}
                                    disabled={
                                        !isData.transactionReason ||
                                        !rSelected ||
                                        !isData.amount ||
                                        isInsertButtonDisabled
                                    }
                                >
                                    Insert
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
                {isLoadingTransactions ? (
                    <Card style={{ textAlign: "center" }}>
                        <Spinner className="m-10">Loading...</Spinner>
                    </Card>
                ) : (
                    <>
                        <Card className="mb-4 shadow" style={{ padding: "1rem" }}>
                            <CardHeader className="border-0">
                                <Row>
                                    <Col style={{ paddingLeft: "0rem" }}>
                                        <h3 className="mb-0 headerTitle">Transactions</h3>
                                    </Col>
                                    <div className="justify-content-end" md="4" lg="4">
                                        <FormGroup className="mb-2">
                                            <Dropdown
                                                isOpen={monthDropdownOpen}
                                                toggle={() =>
                                                    setMonthDropdownOpen(!monthDropdownOpen)
                                                }
                                                className="filterButton"
                                                style={{ width: "232px !important" }}
                                            >
                                                <DropdownToggle caret>
                                                    {selectedMonth ? selectedMonth : "Filter by Month"}
                                                </DropdownToggle>
                                                {months && months.length > 0 ? (
                                                    <DropdownMenu>
                                                        {months.map((month) => (
                                                            <DropdownItem
                                                                key={month.label}
                                                                value={month.label}
                                                                onClick={() =>
                                                                    filterBySearch(
                                                                        month.label
                                                                    )
                                                                }
                                                            >
                                                                {month.label}
                                                            </DropdownItem>
                                                        ))}
                                                    </DropdownMenu>
                                                ) : null}
                                            </Dropdown>
                                        </FormGroup>
                                        <DatePicker
                                            showIcon
                                            selected={selectedDate}
                                            onChange={(date) => filterByDate(date)}
                                            className="dateSelector"
                                            icon="fa fa-calendar"
                                            placeholderText="Filter by Date"
                                        />
                                    </div>
                                </Row>
                            </CardHeader>
                            <Table className="align-items-center" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Description</th>
                                        <th scope="col" style={{ textAlign: "center" }}>Created By</th>
                                        <th scope="col" style={{ textAlign: "center" }}>Updated By</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Amount (Rs.)</th>
                                        <th scope="col" style={{ textAlign: "center" }}>Edit</th>
                                    </tr>
                                </thead>
                                {!isAllTransactions ? (
                                    <tbody>
                                        <tr style={{ textAlign: "center" }}>
                                            <td colSpan="12" style={{ textAlign: "center" }}>
                                                <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" alt="No Data Available" style={{ margin: "auto" }} />
                                            </td>
                                        </tr>
                                    </tbody>) :
                                    <tbody>
                                        {allTransactions?.map((transaction, index) => (
                                            <tr key={index} className={transaction.amount < 0 ? 'creditTransaction' : 'debitTransaction'}>
                                                <td>{(transaction.date).split('.')[0]}</td>
                                                <td>{transaction.description}</td>
                                                <td style={{ textAlign: "center" }}>{transaction.user.full_name}</td>
                                                <td style={{ textAlign: "center" }}>{transaction.updated_by?.full_name}</td>
                                                <td style={{ textAlign: "right" }}>{Math.abs(transaction.amount)}</td>
                                                <td style={{ textAlign: "center" }}>
                                                    <Button
                                                        color="primary"
                                                        type="button"
                                                        id="editTeacher"
                                                        onClick={() => handleEditClick(transaction)}
                                                    >
                                                        <BiEdit />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>}
                                {!isAllTransactions ? null : (
                                    classFeesBalance !== null ? (
                                        <tbody>
                                            <tr className="debitTransaction classFeesBalanceRow">
                                                <td colSpan="4" style={{ textAlign: "right", fontSize: "16px" }}>Class Fees Balance</td>
                                                <td style={{ textAlign: "right", fontSize: "16px" }}>{classFeesBalance}</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    ) : null
                                )}

                                {balance !== null ? (
                                    <tbody>
                                        <tr>
                                            <th colSpan="4" style={{ textAlign: "right", fontSize: "20px" }}>Transaction Balance</th>
                                            <th style={{ textAlign: "right", fontSize: "20px" }}>{balance}</th>
                                        </tr>
                                    </tbody>
                                ) : null}
                            </Table>
                            <div style={{ paddingTop: "10px" }}>
                                {pageCount > 1 ?
                                    <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                                        currentStart={currentStart}
                                        setCurrentStart={setCurrentStart}></Paginations>
                                    : null}
                            </div>
                        </Card>
                        <Card className="mb-4 shadow" style={{ padding: "1rem 1rem 2rem 1rem" }}>
                            <CardHeader className="border-0">
                                <Row className="expenseSummaryHeader">
                                    <div className="expenseSummaryTitle" >
                                        <h3 className="mb-0">Expense Summary</h3>
                                    </div >
                                    <div className="expenseSummaryDropdown" >
                                        <FormGroup>
                                            <Dropdown
                                                isOpen={monthSummaryDropdownOpen}
                                                toggle={() =>
                                                    setMonthSummaryDropdownOpen(!monthSummaryDropdownOpen)
                                                }
                                                className="transationFilterButton"
                                                style={{ width: "232px !important" }}
                                            >
                                                <DropdownToggle caret>
                                                    {selectedSummaryMonth ? selectedSummaryMonth : "Filter by Month"}
                                                </DropdownToggle>
                                                {months && months.length > 0 ? (
                                                    <DropdownMenu>
                                                        {months.map((month) => (
                                                            <DropdownItem
                                                                key={month.label}
                                                                value={month.label}
                                                                onClick={() =>
                                                                    filterBySummarySearch(
                                                                        month.label
                                                                    )
                                                                }
                                                            >
                                                                {month.label}
                                                            </DropdownItem>
                                                        ))}
                                                    </DropdownMenu>
                                                ) : null}
                                            </Dropdown>
                                        </FormGroup>
                                    </div>
                                </Row>
                            </CardHeader>
                            {!isLoadingTransactions && !isAllTransactionSummary ? (
                                <Row style={{ textAlign: "center" }}>
                                    <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" alt="No Data Available" style={{ margin: "auto" }} />
                                </Row>
                            ) :
                                <Row>
                                    <Col lg="5">
                                        <Table className="align-items-center" responsive>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Expeses</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allTransactionSummary?.map((transaction, index) => (
                                                    <tr key={index}>
                                                        <td>{transaction.date}</td>
                                                        <td>{Math.abs(transaction.day_expenses)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        <div style={{ paddingTop: "10px" }}>
                                            {summaryPageCount > 1 ?
                                                <Paginations totalPages={summaryPageCount} handlePagination={handleSummaryPagination} currentPage={currentSummaryPage}
                                                    currentStart={currentStart}
                                                    currentSummaryStart={setCurrentSummaryStart}></Paginations>
                                                : null}
                                        </div>
                                    </Col>
                                    <Col lg="7">
                                        <div>
                                            <Pie
                                                data={data}
                                                options={{
                                                    responsive: true,
                                                    maintainAspectRatio: true
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </Card>
                        <Card className="mb-4 shadow" style={{ padding: "1rem" }}>
                            <CardHeader className="border-0">
                                <Row>
                                    <Col style={{ paddingLeft: "0rem" }}>
                                        <h3 className="mb-0 headerTitle">Teachers' Payments</h3>
                                    </Col>
                                    <div className="justify-content-end" md="4" lg="4">
                                        <FormGroup className="mb-2">
                                            <Dropdown
                                                isOpen={monthTeacherDropdownOpen}
                                                toggle={() =>
                                                    setMonthTeacherDropdownOpen(!monthTeacherDropdownOpen)
                                                }
                                                className="filterButton"
                                                style={{ width: "232px !important" }}
                                            >
                                                <DropdownToggle caret>
                                                    {selectedTeacherMonth ? selectedTeacherMonth : "Filter by Month"}
                                                </DropdownToggle>
                                                {months && months.length > 0 ? (
                                                    <DropdownMenu>
                                                        {months.map((month) => (
                                                            <DropdownItem
                                                                key={month.label}
                                                                value={month.label}
                                                                onClick={() =>
                                                                    filterByTeacherSearch(
                                                                        month.label
                                                                    )
                                                                }
                                                            >
                                                                {month.label}
                                                            </DropdownItem>
                                                        ))}
                                                    </DropdownMenu>
                                                ) : null}
                                            </Dropdown>
                                        </FormGroup>
                                        <DatePicker
                                            showIcon
                                            selected={selectedTeacherDate}
                                            onChange={(date) => filterByTeacherDate(date)}
                                            className="dateSelector"
                                            icon="fa fa-calendar"
                                            placeholderText="Filter by Date"
                                        />
                                    </div>
                                </Row>
                            </CardHeader>
                            <Table className="align-items-center" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Full Name</th>
                                        <th scope="col">Phone Number</th>
                                        <th scope="col" style={{ textAlign: "right" }}>Amount (Rs.)</th>
                                    </tr>
                                </thead>
                                {!isAllTeacherPayments ? (
                                    <tbody>
                                        <tr style={{ textAlign: "center" }}>
                                            <td colSpan="12" style={{ textAlign: "center" }}>
                                                <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" alt="No Data Available" style={{ margin: "auto" }} />
                                            </td>
                                        </tr>
                                    </tbody>) :
                                    <tbody>
                                        {allTeacherPayments?.map((transaction, index) => (
                                            <tr key={index}>
                                                <td>{transaction.full_name}</td>
                                                <td>{transaction.phone_number}</td>
                                                <td style={{ textAlign: "right" }}>{transaction.total}</td>
                                            </tr>
                                        ))}
                                    </tbody>}
                            </Table>
                            <div style={{ paddingTop: "10px" }}>
                                {pageTeacherCount > 1 ?
                                    <Paginations totalPages={pageTeacherCount} handlePagination={handleTeacherPagination} currentPage={currentTeacherPage}
                                        currentStart={currentTeacherStart}
                                        setCurrentStart={setCurrentTeacherStart}></Paginations>
                                    : null}
                            </div>
                        </Card>
                    </>
                )}
                <Modal
                    className="modal-dialog-centered"
                    isOpen={editModal}
                    toggle={() => [setEditmodal(false)]}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Edit Transaction
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => [
                                setEditmodal(false),
                                setDisabledEditButton(true),
                                setEditError({
                                    full_name: "",
                                    phone_number: "",
                                    password: "",
                                }),
                            ]}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Input
                                            id="editDescription"
                                            placeholder="Description"
                                            type="text"
                                            value={
                                                selectedTransaction?.description
                                                    ? selectedTransaction?.description
                                                    : ""
                                            }
                                            readOnly
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Input
                                            id="editAmount"
                                            placeholder="Amount"
                                            type="text"
                                            value={
                                                selectedTransaction?.amount
                                                    ? Math.abs(selectedTransaction?.amount)
                                                    : ""
                                            }
                                            name="amount"
                                            onChange={(e) => {
                                                setSelectedTransaction({
                                                    ...selectedTransaction,
                                                    amount: e.target.value,
                                                });
                                                setEditedInput(true);
                                                setDisabledEditButton(false);
                                                setEditError({ amount: "" });
                                            }}
                                        />
                                        {isEditError.amount && (
                                            <p className="text-danger">{isEditError.amount}</p>
                                        )}
                                    </FormGroup>
                                    <Button
                                        color="primary"
                                        type="button"
                                        onClick={() => editTransactionDetails()}
                                        disabled={!isEditedInput || isDisabledEditButton}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>
            </div >
        </>
    );
};

export default Transactions;
