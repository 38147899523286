import StudentPaymentReceipt from "components/Reports/StudentPaymentReceipt";
import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardHeader,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  Label,
  Modal,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import "../../../assets/css/class-assistant-payments.css";
import StudentImage from "../../../assets/img/brand/blank_profile_pic.png";
import { getLastThreeMonthsFeeByClassAssistants } from "actions/ClassAssistantLayout";
import dateFormat, { masks } from "dateformat";
import { updateStudentPaymentByClassAssistant } from "actions/ClassAssistantLayout";
import { getStudentByClassAssistants } from "actions/ClassAssistantLayout";
import { useReactToPrint } from "react-to-print";
import { SlArrowUp, SlArrowDown, SlArrowDownCircle } from "react-icons/sl";
import { getUser } from "actions/Auth";
import { filterStudentListBySearch } from "actions/Students";
import { filterStudentListByAssistant } from "actions/ClassAssistantLayout";

const ClassAssistantStudentPayments = () => {
  const receiptRef = useRef();
  const barcodeInputRef = useRef(null);
  const [isData, setData] = useState({
    barcode: "",
    student_id: "",
  });

  const Status = {
    1: "Paid",
    0: "Unpaid",
  };

  const PayTypes = {
    FULL: "FULL",
    HALF_FREE: "HALF_FREE",
    FULL_FREE: "FULL_FREE",
  };

  const [selectedTwoMonthsAgo, setSelectedTwoMonthsAgo] = useState(null);
  const [selectedLastMonth, setSelectedLastMonth] = useState(null);
  const [selectedThisMonth, setSelectedThisMonth] = useState(null);
  const [barcode, setBarcode] = useState("");
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [months, setMonths] = useState([]);
  const [studentClasses, setStudentClasses] = useState(null);
  const [groupedData, setGroupedData] = useState([]);
  const [barcodeInput, setBarcodeInput] = useState("");
  const [userId, setUserId] = useState(null);
  const [editModal, setEditmodal] = useState(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [isDisabledEditButton, setDisabledEditButton] = useState(true);
  const [isEditedInput, setEditedInput] = useState(false);
  const [payTypeDropdownOpen, setPayTypeDropdownOpen] = useState(false);

  const [classPayment, setClassPayment] = useState({
    month: "",
    year: "",
    classUserId: "",
    paymentType: "",
    feeType: "",
    date: "",
  });
  const [selectedFeeType, setSelectedFeeType] = useState({});
  const [student, setStudent] = useState({});
  const [receiptData, setReceiptData] = useState({
    subject: "",
    grade: "",
    year: "",
    teacherName: "",
    invoiceNo: "",
    invoiceDate: "",
    paidMoth: "",
    paidAmount: "",
    studentName: "",
    barcode: "",
    payType: "",
  });

  const fetchData = async () => {
    const response = await getUser();
    setUserId(response.id);
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // 1-indexed month

    let newDate = `${year}-${month}`;
    let prevMonth = month - 1;
    let twoMonthsAgo = month - 2;

    if (month === 1) {
      prevMonth = `${year - 1}-12`;
      twoMonthsAgo = `${year - 1}-11`;
    } else if (month === 2) {
      prevMonth = `${year - 1}-01`;
      twoMonthsAgo = `${year - 1}-12`;
    } else {
      prevMonth = `${year}-${prevMonth}`;
      twoMonthsAgo = `${year}-${twoMonthsAgo}`;
    }

    setMonths([twoMonthsAgo, prevMonth, newDate]);
  };

  const handleOpenScanner = () => {
    setIsScannerOpen(true);
  };

  const handleCloseScanner = () => {
    setIsScannerOpen(false);
  };

  const groupByTeacherGradeSubject = async (data) => {
    const grouped = data.reduce((acc, item) => {
      const { classUserId, teacher, subject, grade, price, user_fee_type } =
        item;
      const key = `${classUserId}`;

      if (!acc[key]) {
        acc[key] = {
          teacherName: teacher,
          subjectName: subject,
          gradeName: grade,
          classFee: price,
          feeType: user_fee_type,
          items: [],
        };
      }

      acc[key].items.push(item);
      return acc;
    }, {});
    const result = Object.keys(grouped).map((key) => ({
      classUserId: key,
      teacherName: grouped[key].teacherName,
      subjectName: grouped[key].subjectName,
      gradeName: grouped[key].gradeName,
      classFee: grouped[key].classFee,
      feeType: grouped[key].feeType,
      items: grouped[key].items,
    }));

    setGroupedData(result);
  };

  let barcodeString = "";

  const handleKeyDown = async (event) => {
    // if (event.key === 'Enter') {
    //     const studentDetails = await getStudentByClassAssistants(barcodeString);
    //     setStudent(studentDetails.students);
    //     const response = await getLastThreeMonthsFeeByClassAssistants({
    //         barcode: barcodeString,
    //         months: months
    //     });
    //     if (response.length > 0) {
    //         setStudentClasses(response);
    //         await groupByTeacherGradeSubject(response);
    //     } else {
    //         setStudentClasses(null);
    //     }
    //     setBarcodeInput(barcodeString);
    //     barcodeString = '';
    // } else {
    const barcodePattern = /^[0-9]$/;
    if (barcodePattern.test(event.key)) {
      barcodeString += event.key;
      if (barcodeString.length === 7) {
        // Process the full barcode data here
        barcodeInputRef.current.value = barcodeString;
      }
    }
    // }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const getStudentPay = async (barcode) => {
    if (barcode.length <= 7) {
      setBarcodeInput(barcode);
    } else if (barcode.length > 7) {
      barcode = barcode.slice(7);
      setBarcodeInput(barcode);
    }
    setBarcode(barcode);
    try {
      if (barcode.length === 7) {
        const studentDetails = await getStudentByClassAssistants(barcode);
        setStudent(studentDetails.students);
        const response = await getLastThreeMonthsFeeByClassAssistants({
          barcode: barcode,
          months: months,
        });
        if (response.length > 0) {
          setStudentClasses(response);
          await groupByTeacherGradeSubject(response);
        } else {
          setStudentClasses(null);
        }
      } else {
        setStudentClasses(null);
      }
    } catch (error) {
      setStudentClasses(null);
    }
  };

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    if (/^\d*$/.test(value)) {
      setBarcodeInput(value); // Update the input field's state

      // If the input field is the barcode, fetch data based on the barcode value
      if (name === "barcode") {
        await getStudentPay(value);
      }
    }
  };

  const resetSelections = () => {
    setSelectedTwoMonthsAgo(null);
    setSelectedLastMonth(null);
    setSelectedThisMonth(null);
    setStudentClasses(null);
    setGroupedData([]);
    setStudent({ registration_number: "" });
    setBarcodeInput("");
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus();
    }
  };

  // const handlePrint = () => {
  //     if (receiptRef.current) {
  //         receiptRef.current.triggerPrint();
  //     }
  // };

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  const handleUpdate = (err, result) => {
    if (err) {
      console.error(err);
    }
    if (result) {
      setBarcode(result.text);
      handleCloseScanner();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRadioChange = (key, feeType) => {
    setSelectedFeeType((prevState) => ({
      [key]: feeType,
    }));
  };

  // const makeAPayment = async (details) => {
  //     setReceiptData({
  //         teacherName: details.teacher,
  //         subject: details.subject,
  //         grade: details.grade,
  //         year: details.year,
  //     });
  //     try {
  //         const currentDate = new Date();
  //         const previousMonthDate = new Date(currentDate);
  //         const month = currentDate.getMonth() + 1;
  //         const todayDate = String(currentDate.getDate()).padStart(2, '0');
  //         let preMonth = new Date(currentDate);;
  //         if (month !== details.month) {
  //             preMonth = new Date(details.year, details.month - 1, 15);
  //         } else {
  //             preMonth = new Date(details.year, details.month - 1, todayDate);
  //         }
  //         const formatedDate = dateFormat(preMonth, "yyyy-mm-dd h:MM:ss");
  //         setClassPayment((prevPayment) => ({
  //             month: details.month,
  //             year: details.year,
  //             classUserId: details.classUserId,
  //             paymentType: details.payment_type,
  //             feeType: selectedFeeType.selectedType ? selectedFeeType.selectedType : details.user_fee_type,
  //             date: formatedDate
  //         }));

  //         let typeVar;
  //         if (Object.keys(selectedFeeType).length !== 0) {
  //             const [[key, value]] = Object.entries(selectedFeeType);
  //             if (key === `${details.classUserId}-${details.month}`) {
  //                 typeVar = value;
  //             } else {
  //                 typeVar = 'FULL';
  //             }
  //         } else {
  //             typeVar = 'FULL';
  //         }

  //         const payDetails = ({
  //             month: details.month,
  //             year: details.year,
  //             classUserId: +details.classUserId,
  //             paymentType: details.payment_type,
  //             feeType: typeVar,
  //             date: formatedDate
  //         });
  //         const response = await updateStudentPaymentByClassAssistant(payDetails);
  //         setReceiptData({
  //             invoiceNo: response.data.invoice_number,
  //             paidAmount: response.data.amount,
  //             invoiceDate: response.data.date,
  //             payType: response.data.fee_type
  //         });
  //         if(response.success === true){
  //             await getStudentPay(barcode);
  //             handlePrint();
  //         }
  //     } catch (error) {

  //     }
  // }

  const [selectedClass, setSelectedClass] = useState(null);

  const makePayTypeFull = async () => {
    try {
      const payDetails = {
        month: selectedClass.month,
        year: selectedClass.year,
        classUserId: +selectedClass.classUserId,
        invoice_number: selectedClass.invoice_number,
        feeType: "FULL",
        status: selectedClass.status,
        userId: +userId,
      };
      const response = await updateStudentPaymentByClassAssistant(payDetails);
      if (response.success === true) {
        setEditmodal(false);
        await getStudentPay(barcode);
      }
    } catch (error) {
      console.error("Payment error: ", error);
    }
  };

  const makePayTypeHalf = async () => {
    try {
      const payDetails = {
        month: selectedClass.month,
        year: selectedClass.year,
        classUserId: +selectedClass.classUserId,
        invoice_number: selectedClass.invoice_number,
        feeType: "HALF_FREE",
        status: selectedClass.status,
        userId: +userId,
      };
      const response = await updateStudentPaymentByClassAssistant(payDetails);
      if (response.success === true) {
        setEditmodal(false);
        await getStudentPay(barcode);
      }
    } catch (error) { }
  };

  const makePayTypeFree = async () => {
    try {
      const payDetails = {
        month: selectedClass.month,
        year: selectedClass.year,
        classUserId: +selectedClass.classUserId,
        invoice_number: selectedClass.invoice_number,
        feeType: "FULL_FREE",
        status: selectedClass.status,
        userId: +userId,
      };
      const response = await updateStudentPaymentByClassAssistant(payDetails);
      if (response.success === true) {
        setEditmodal(false);
        await getStudentPay(barcode);
      }
    } catch (error) {
      console.error("Payment error: ", error);
    }
  };

  const cancelPayment = async () => {
    try {
      const payDetails = {
        month: selectedClass.month,
        year: selectedClass.year,
        classUserId: +selectedClass.classUserId,
        invoice_number: selectedClass.invoice_number,
        status: false,
        userId: +userId,
      };
      const response = await updateStudentPaymentByClassAssistant(payDetails);
      if (response.success === true) {
        setEditmodal(false);
        await getStudentPay(barcode);
      }
    } catch (error) {
      console.error("Payment error: ", error);
    }
  };

  const makeAPayment = async (details) => {
    try {
      if (details.amount !== null) {
        setSelectedClass(details);
        setEditmodal(true);
      } else {
        const currentDate = new Date();
        const previousMonthDate = new Date(currentDate);
        const month = currentDate.getMonth() + 1;
        const todayDate = String(currentDate.getDate()).padStart(2, "0");
        let preMonth = new Date(currentDate);
        if (month !== details.month) {
          preMonth = new Date(details.year, details.month - 1, 15);
        } else {
          preMonth = new Date(details.year, details.month - 1, todayDate);
        }
        const formatedDate = dateFormat(preMonth, "yyyy-mm-dd h:MM:ss");

        let typeVar;
        if (Object.keys(selectedFeeType).length !== 0) {
          const [[key, value]] = Object.entries(selectedFeeType);

          if (key === `${details.classUserId}-${details.month}`) {
            typeVar = value;
          } else {
            typeVar = details.user_fee_type;
          }
        } else {
          typeVar = details.user_fee_type;
        }

        const payDetails = {
          month: details.month,
          year: details.year,
          classUserId: +details.classUserId,
          paymentType: details.payment_type,
          feeType: typeVar,
          date: formatedDate,
          paid_date: dateFormat(currentDate, "yyyy-mm-dd h:MM:ss"),
          userId: +userId,
        };
        const response = await updateStudentPaymentByClassAssistant(payDetails);
        setReceiptData({
          teacherName: details.teacher,
          subject: details.subject,
          grade: details.grade,
          year: details.year,
          month: details.month,
          barcode: student.barcode,
          studentName: student.full_name,
          invoiceNo: response.data.invoice_number,
          paidAmount: response.data.amount,
          invoiceDate: currentDate,
          payType: response.data.fee_type,
        });
        if (response.success === true) {
          await getStudentPay(barcode);
          handlePrint();
        }
      }
    } catch (error) {
      console.error("Payment error: ", error);
    }
  };

  const [visibleOptionIndex, setVisibleOptionIndex] = useState(null);

  const displayOptions = (index) => {
    setVisibleOptionIndex((prevIndex) => (prevIndex === index ? null : index)); // Toggle visibility
  };

  const [searchItem, setSearchItem] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(null);

  const handleInputChange1 = async (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);
    if (searchTerm.length >= 3) {
      const response = await filterStudentListByAssistant(searchTerm);

      const filteredItems = response.data.data.filter((user) =>
        user.full_name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setFilteredUsers(filteredItems);
    }
  };

  const [nullMessage, setNullMessage] = useState(false);
  const getStudentBySeacrh = async (student) => {
    try {
      setNullMessage(false);
      setFilteredUsers(null);
      const studentDetails = await getStudentByClassAssistants(student);
      setStudent(studentDetails.students);
      setBarcodeInput(student);
      const response = await getLastThreeMonthsFeeByClassAssistants({
        barcode: student,
        months: months,
      });
      if (response.length > 0) {
        setStudentClasses(response);
        await groupByTeacherGradeSubject(response);
      } else {
        setNullMessage(true);
        setStudentClasses(null);
      }
    } catch (error) { }
  };

  const [searchbar, setSearchBar] = useState(false);

  return (
    <>
      <div className="mt--3 container-fluid assistant-container">
        <Card className="mt-4 mb-4 pb-4 shadow">
          <CardHeader className="border-0 assistantStudentHeader">
            <div
              className="headerLeft"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3 className="mb-0 mr-2">Student Payments</h3>
              <i
                class="fa-solid fa-magnifying-glass"
                style={{ cursor: "pointer" }}
                onClick={() => setSearchBar(!searchbar)}
              ></i>
            </div>
          </CardHeader>
          {searchbar ? (
            <div className="ml-2">
              <Col md="4">
                <FormGroup>
                  <Input
                    type="text"
                    value={searchItem}
                    onChange={handleInputChange1}
                    placeholder="Search..."
                    style={{
                      borderBottomLeftRadius: searchItem !== "" ? "0px" : "",
                      borderBottomRightRadius: searchItem !== "" ? "0px" : "",
                    }}
                  />
                  {searchItem && (
                    <ul
                      style={{
                        position: "absolute",
                        width: "94%",
                        listStyleType: "none",
                        padding: "0",
                        border: "1px solid #ddd",
                        zIndex: 10,
                        backgroundColor: "white",
                        display: filteredUsers === null ? "none" : "",
                      }}
                    >
                      {filteredUsers?.map((option, index) => (
                        <li
                          key={index}
                          style={{
                            padding: "8px",
                            borderBottom: "1px solid #ddd",
                            cursor: "pointer",
                          }}
                          onClick={() => getStudentBySeacrh(option.barcode)}
                        >
                          {option.full_name}
                        </li>
                      ))}
                    </ul>
                  )}
                </FormGroup>
              </Col>
            </div>
          ) : null}
          <div className="ml-4 barcodeSearch">
            <FormGroup>
              <Input
                id="barcode"
                placeholder="Barcode"
                type="text"
                value={barcodeInput}
                onChange={handleInputChange}
                ref={barcodeInputRef}
                name="barcode"
                pattern="\d*"
                className="barcodeSearchInput"
                autoFocus
              />
            </FormGroup>
            <FormGroup>
              <Input
                id="student_id"
                placeholder="Student Id"
                type="text"
                value={student?.registration_number}
                name="student_id"
                disabled
                className="barcodeSearchInput"
              />
            </FormGroup>
            <FormGroup>
              <Button onClick={resetSelections} color="primary">
                Reset
              </Button>
            </FormGroup>
          </div>
          {nullMessage ?
            <div style={{ paddingLeft: "20px" }}>
              {student.full_name} has not enrolled to any class yet!
            </div>
            : null}
          <div className="classList">
            <div className="bodySection">
              <div className="classPaymentOptions">
                {studentClasses !== null ? (
                  groupedData.map((studentClass, classIndex) => (
                    <Form
                      className="ml-4 mb-2 assistantStudentPayments"
                      key={classIndex}
                    >
                      <div className="classDetails">
                        <h3>{studentClass.teacherName}</h3>
                        <p>
                          {studentClass.subjectName} - {studentClass.gradeName}
                        </p>
                        <p>Rs.{studentClass.classFee}.00</p>
                        <p style={{ color: "green" }}>{studentClass.feeType}</p>
                      </div>
                      <div className="payHistory">
                        {studentClass.items.map((classes, index) => (
                          <div className="paymentOptions" key={index}>
                            <div className="thisMonth">
                              <FormGroup
                                tag="fieldset"
                                className="fieldSetPayOptions"
                              >
                                <Label style={{ fontWeight: "bold" }}>
                                  {classes.year}-
                                  {classes.month.toString().padStart(2, "0")}
                                </Label>
                                <div
                                  className="radioOptions"
                                  style={{
                                    display:
                                      visibleOptionIndex === classIndex
                                        ? ""
                                        : "none",
                                  }}
                                >
                                  <FormGroup check>
                                    <Input
                                      name={`${classes.classUserId}-${classes.month}`}
                                      type="radio"
                                      checked={
                                        classes.amount !== null
                                          ? classes.fee_type === "FULL"
                                          : selectedFeeType[
                                          `${classes.classUserId}-${classes.month}`
                                          ] === "FULL"
                                      }
                                      onChange={() =>
                                        handleRadioChange(
                                          `${classes.classUserId}-${classes.month}`,
                                          "FULL"
                                        )
                                      }
                                      disabled={classes.amount !== null}
                                    />{" "}
                                    <Label check>Full</Label>
                                  </FormGroup>
                                  <FormGroup check>
                                    <Input
                                      name={`${classes.classUserId}-${classes.month}`}
                                      type="radio"
                                      checked={
                                        classes.amount !== null
                                          ? classes.fee_type === "HALF_FREE"
                                          : selectedFeeType[
                                          `${classes.classUserId}-${classes.month}`
                                          ] === "HALF_FREE"
                                      }
                                      onChange={() =>
                                        handleRadioChange(
                                          `${classes.classUserId}-${classes.month}`,
                                          "HALF_FREE"
                                        )
                                      }
                                      disabled={classes.amount !== null}
                                    />{" "}
                                    <Label check>Half</Label>
                                  </FormGroup>
                                  <FormGroup check>
                                    <Input
                                      name={`${classes.classUserId}-${classes.month}`}
                                      type="radio"
                                      checked={
                                        classes.amount !== null
                                          ? classes.fee_type === "FULL_FREE"
                                          : selectedFeeType[
                                          `${classes.classUserId}-${classes.month}`
                                          ] === "FULL_FREE"
                                      }
                                      onChange={() =>
                                        handleRadioChange(
                                          `${classes.classUserId}-${classes.month}`,
                                          "FULL_FREE"
                                        )
                                      }
                                      disabled={classes.amount !== null}
                                    />{" "}
                                    <Label check>Free</Label>
                                  </FormGroup>
                                </div>
                              </FormGroup>
                              <div className="paymentBtn">
                                <Button
                                  className={
                                    classes.amount !== null
                                      ? "paidBtn"
                                      : "unpaidBtn"
                                  }
                                  onClick={() => makeAPayment(classes)}
                                >
                                  {classes.amount !== null ? `Edit` : `Pay`}
                                </Button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="visiblePayOptions">
                        {visibleOptionIndex === classIndex ? (
                          <SlArrowDown
                            onClick={() => displayOptions(classIndex)}
                          />
                        ) : (
                          <SlArrowUp
                            onClick={() => displayOptions(classIndex)}
                          />
                        )}
                      </div>
                    </Form>
                  ))
                ) : (
                  null
                )}
              </div>
            </div>
            {studentClasses !== null ? (
              <div className="studentSection">
                <div className="imageStudent">
                  <img src={StudentImage} className="studentPaymentImage" />
                </div>
                <div className="studentDetailsClassAssistant">
                  <h3><i class="fa-solid fa-circle-user"></i> {student.full_name}</h3>
                  <p style={{ fontSize: "12px" }}><i class="fa-solid fa-registered"></i> {student.registration_number}</p>
                  {student.phone_number !== null ? <p><i class="fa-solid fa-phone-volume"></i> {student.phone_number}</p> : null}
                  {/* <Button className="inactivate">Inactivate</Button> */}
                </div>
              </div>
            ) : null}
          </div>
        </Card>

        <Modal
          className="modal-dialog-centered"
          isOpen={editModal}
          toggle={() => [setEditmodal(false)]}
        >
          <div className="modal-header pb-0">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Payment Details
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => [setEditmodal(false), setDisabledEditButton(true)]}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody className="pt-0">
            <Form>
              <Row>
                <Col>
                  <p>Teacher: {selectedClass?.teacher}</p>
                  <p>
                    Class: {selectedClass?.subject} - {selectedClass?.grade}
                  </p>
                  <p>Amount: {selectedClass?.amount}.00</p>
                  <p>Invoice No: {selectedClass?.invoice_number}</p>
                  <p>
                    Month: {selectedClass?.year}-
                    {selectedClass?.month.toString().padStart(2, "0")}
                  </p>
                  <div className="payEdtiBtnsSection mt-3">
                    <div className="payTypeEditBtn">
                      <Button
                        color={
                          selectedClass?.fee_type === "FULL"
                            ? "primary"
                            : "default"
                        }
                        type="button"
                        onClick={() => makePayTypeFull()}
                        disabled={selectedClass?.fee_type === "FULL"}
                      >
                        Full
                      </Button>
                      <Button
                        color={
                          selectedClass?.fee_type === "HALF_FREE"
                            ? "primary"
                            : "default"
                        }
                        type="button"
                        onClick={() => makePayTypeHalf()}
                        disabled={selectedClass?.fee_type === "HALF_FREE"}
                      >
                        Half
                      </Button>
                      <Button
                        color={
                          selectedClass?.fee_type === "FULL_FREE"
                            ? "primary"
                            : "default"
                        }
                        type="button"
                        onClick={() => makePayTypeFree()}
                        disabled={selectedClass?.fee_type === "FULL_FREE"}
                      >
                        Free
                      </Button>
                    </div>
                    <div className="payCancelBtn">
                      <Button
                        color="danger"
                        type="button"
                        onClick={() => cancelPayment()}
                      >
                        Payment Cancel
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
        <div style={{ display: "none" }}>
          <StudentPaymentReceipt ref={receiptRef} allDetails={receiptData} />
        </div>
      </div>
    </>
  );
};

export default ClassAssistantStudentPayments;
