import {
  Table,
  Card,
  CardHeader,
  Modal,
  Button,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Spinner,
  InputGroup,
} from "reactstrap";
import { useState, useEffect } from "react";
import SecondaryHeader from "components/Headers/SecondaryHeader";
import { createStudent } from "actions/Students";
import { editStudent } from "actions/Students";
import { Link } from "react-router-dom";
import { getAllActiveGrades } from "actions/Grades";
import { findTeachersByGradeId } from "actions/Teachers";
import { findAllSubjectsByTeacherIdAndGradeId } from "actions/Subjects";
import html2canvas from 'html2canvas';
import { createRoot } from 'react-dom/client';
import { BiEdit } from "react-icons/bi";
import { LiaQrcodeSolid } from "react-icons/lia";

import "../../assets/css/qr-card-styles.css";
import "../../assets/css/styles.css";
import QRCard from "./QRCard";
import Paginations from "components/Pagination/Paginations";
import { getStudentsByLimit } from "actions/Students";
import { filterStudentList } from "actions/Students";

const Students = () => {
  const Status = {
    ACTIVE: "ACTIVE",
    BANNED: "BANNED",
    INACTIVE: "INACTIVE",
    PENDING: "PENDING",
    DEACTIVATED: "DEACTIVATED",
  };

  const [allStudents, setAllStudents] = useState(null);
  const [isAllStudents, setIsAllStudents] = useState(false);

  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [editModal, setEditmodal] = useState(false);
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [isInsertButtonDisabled, setinsertButtonDisabled] = useState(false);
  const [isSuccessMessage, setSuccessMessage] = useState("");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [isLoadingStudents, setLoadingStudents] = useState(true);
  const [isEditedInput, setEditedInput] = useState(false);
  const [isDisabledEditButton, setDisabledEditButton] = useState(true);
  const [allGrades, setAllGrades] = useState(null);
  const [gradeDropdownOpen, setGradeDropdownOpen] = useState(false);
  const [selectedGradeForStudent, setSelectedGradeForStudent] = useState(null);
  const [allTeachersByGrade, setAllTeachersByGrade] = useState(null);
  const [teacherDropdownOpen, setTeacherDropdownOpen] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [allSubjects, setAllSubjects] = useState(null);
  const [subjectDropdownOpen, setSubjectDropdownOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPge, SetItemsPerPage] = useState(10);
  const [skip, setSkip] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);
  const [gradeFilterDropdownOpen, setGradeFilterDropdownOpen] = useState(false);
  const [subjectFilterDropdownOpen, setSubjectFilterDropdownOpen] = useState(false);
  const [teacherFilterDropdownOpen, setTeacherFilterDropdownOpen] = useState(false);
  const [selectedGradeFilter, setSelectedGradeFilter] = useState(null);
  const [selectedSubjectFilter, setSelectedSubjectFilter] = useState(null);
  const [selectedTeacherFilter, setSelectedTeacherFilter] = useState(null);
  const [allTeachers, setAllTeachers] = useState(null);
  const [allActiveSubjects, setAllActiveSubjects] = useState(null);
  const [filterStudent, setFilterStudent] = useState(false);
  const [isFilterError, setFilterError] = useState(false);
  const [enrollClsError, setEnrollClsError] = useState('');

  const [searchQuery, setSearchQuery] = useState("");
  const [initialStudent, setInitialStudent] = useState({
    id: null,
    registration_number: "",
    full_name: "",
    phone_number: "",
    password: "",
    enrolled_classes: [],
    status: "",
  });

  const [isData, setIsData] = useState({
    full_name: "",
    barcode: "",
    enrolled_classes: [],
    phone_number: "",
  });

  const [isError, setIsError] = useState({
    full_name: "",
    email: "",
    phone_number: "",
  });

  const [isEditError, setEditError] = useState({
    full_name: "",
    phone_number: "",
  });

  const barcodePattern = /^[0-9]{0,7}$/;

  const getStudents = async (itemsPerPge, skip) => {
    const students = await getStudentsByLimit(itemsPerPge, skip);
    setPageCount(students.data.pageCount);
    setAllStudents(students.data.data);
    if (students.data.data.length > 0) {
      setIsAllStudents(true);
    }
  }

  const fetchData = async () => {
    try {
      await getStudents(itemsPerPge, skip);
      const grades = await getAllActiveGrades();
      setAllGrades(grades);
      setLoadingStudents(false);
    } catch (error) {
      setLoadingStudents(false);
      setIsAllStudents(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const filterStudents = async (gradeId, subjectId, teacherId) => {
    try {
      setFilterStudent(true);
      const searched = await filterStudentList(itemsPerPge, skip, searchQuery, gradeId, subjectId, teacherId);
      setPageCount(searched.data.pageCount);
      setCurrentStart(0);
      setCurrentPage(skip + 1);
      setAllStudents(searched.data.data);
    } catch (error) {
      setPageCount(0);
      setFilterError(true);
      setAllStudents(null);
    }
  }

  const filterBySearch = async (itemsPerPge, skip, searchQuery, gradeId, subjectId, teacherId) => {
    const searched = await filterStudentList(itemsPerPge, skip, searchQuery, gradeId, subjectId, teacherId);
    setCurrentPage(skip + 1);
    setPageCount(searched.data.pageCount);
    setAllStudents(searched.data.data);
  }

  const resetFilter = async () => {
    getStudents(itemsPerPge, skip);
    setFilterStudent(false);
    setSelectedGradeFilter(null);
    setSelectedSubjectFilter(null);
    setSelectedTeacherFilter(null);
    setAllTeachers(null);
    setAllActiveSubjects(null);
    setSearchQuery("");
    setCurrentStart(0);
    setCurrentPage(1);
  }
  const handlePagination = async (pageNumber) => {
    if (filterStudent) {
      await filterBySearch(itemsPerPge, pageNumber - 1, searchQuery, selectedGradeFilter?.id, selectedSubjectFilter?.id, selectedTeacherFilter?.id);
    } else {
      await getStudents(itemsPerPge, pageNumber - 1);
      setCurrentPage(pageNumber);
    }
  }

  const getTeachers = async (gradeId) => {
    const teachersByGrade = await findTeachersByGradeId(gradeId);
    setAllTeachersByGrade(teachersByGrade.filter(teacher => teacher.status = 'ACTIVE'));
  }

  const getSubjects = async (gradeId, teacherId) => {
    const subjectsByGradeAndTeacher = await findAllSubjectsByTeacherIdAndGradeId(gradeId, teacherId);
    setAllSubjects(subjectsByGradeAndTeacher.filter(subject => subject.status = true));
  }

  const getTeachersToFilter = async (gradeId) => {
    const teachersByGrade = await findTeachersByGradeId(gradeId);
    setAllTeachers(teachersByGrade.filter(teacher => teacher.status = 'ACTIVE'));
  }

  const getSubjectsToFilter = async (gradeId, teacherId) => {
    const subjectsByGradeAndTeacher = await findAllSubjectsByTeacherIdAndGradeId(gradeId, teacherId);
    setAllActiveSubjects(subjectsByGradeAndTeacher.filter(subject => subject.status = true));
  }
  const [selectedClasses, setSelectedClasses] = useState([]);

  const updateSelectedClasses = (grade, teacher, subject) => {
    setSelectedClasses(prevSelectedClasses => [
      ...prevSelectedClasses,
      { grade: grade, teacher: teacher, subject: subject }
    ]);
  };

  const removeSelectedClass = (indexToRemove) => {
    setSelectedClasses(prevSelectedClasses => {
      return prevSelectedClasses.filter((_, index) => index !== indexToRemove);
    });
  };

  const addClass = async () => {
    if (selectedTeacher === null || selectedGradeForStudent === null || selectedSubject === null) {
      setEnrollClsError("Grade, Teacher, and Subject must be selected.")
    } else {
      const newEnrolledClass = {
        teacherId: selectedTeacher.id,
        gradeId: selectedGradeForStudent.id,
        subjectId: selectedSubject.id
      };

      setIsData(prevState => {
        const isAlreadyEnrolled = prevState.enrolled_classes.some(
          enrolledClass =>
            enrolledClass.teacherId === newEnrolledClass.teacherId &&
            enrolledClass.gradeId === newEnrolledClass.gradeId &&
            enrolledClass.subjectId === newEnrolledClass.subjectId
        );

        if (isAlreadyEnrolled) {
          setFailed(true);
          setErrorMessage('This student is already enrolled in this class.');
          return prevState;
        }

        const updatedEnrolledClasses = [...prevState.enrolled_classes, newEnrolledClass];
        return {
          ...prevState,
          enrolled_classes: updatedEnrolledClasses
        };
      });

      setSelectedClasses(prevSelectedClasses => {
        const isAlreadySelected = prevSelectedClasses.some(
          selectedClass =>
            selectedClass.grade === selectedGradeForStudent.name &&
            selectedClass.teacher === selectedTeacher.full_name &&
            selectedClass.subject === selectedSubject.name
        );
        if (!isAlreadySelected) {
          return [
            ...prevSelectedClasses,
            { grade: selectedGradeForStudent.name, teacher: selectedTeacher.full_name, subject: selectedSubject.name }
          ];
        }
        return prevSelectedClasses;
      });
    }
  };

  const removeEnrolledClass = (enrolledClassIdToRemove) => {
    setIsData(prevState => {
      const updatedEnrolledClasses = prevState.enrolled_classes.slice();
      updatedEnrolledClasses.splice(enrolledClassIdToRemove, 1);
      return {
        ...prevState,
        enrolled_classes: updatedEnrolledClasses
      };
    });
    removeSelectedClass(enrolledClassIdToRemove);

  };
  useEffect(() => {
  }, [isData]);

  const handleInputChange = (event) => {
    setIsError({ ...isError, [event.target.name]: "" });
    if (event.target.name === 'barcode') {
      if (barcodePattern.test(event.target.value)) {
        setIsData({ ...isData, [event.target.name]: event.target.value });
      }
    } else {
      setIsData({ ...isData, [event.target.name]: event.target.value });
    }
  };

  const isValidStudent = (value) => {
    const errors = {};
    if (value.full_name.length < 3) {
      errors.full_name =
        "Student Full Name must be at least 6 characters long.";
    }
    if ((value.phone_number.length !== 10)) {
      errors.phone_number = "Value must be a valid phone number.";
    }
    if (value.barcode.length !== 7) {
      errors.barcode =
        "Barcode must be 7 numbers long.";
    }
    if (Object.keys(errors).length > 0) {
      setIsError(errors);
      return false;
    } else {
      return true;
    }
  };

  const insertNewStudent = async () => {
    if (isValidStudent(isData)) {
      setinsertButtonDisabled(true);
      const response = await createStudent(isData);
      if (response.status === 201) {
        setSuccessMessage("Succesfully Created the Student!");
        setSuccess(true);
        setIsData({
          ...isData,
          full_name: "",
          phone_number: "",
          barcode: "",
          enrolled_classes: []
        });
        setSelectedGradeForStudent(null);
        setSelectedTeacher(null);
        setSelectedSubject(null);
        setSelectedClasses([]);
        fetchData();
        setinsertButtonDisabled(false);
      } else {
        setErrorMessage(response.message);
        setinsertButtonDisabled(false);
        setFailed(true);
      }
    }
  };

  const handleEditClick = (student) => {
    setSelectedStudent(student);
    setInitialStudent(student);
    setEditmodal(true);
  };

  const isValidEditStudent = (value) => {
    const errors = {};
    if (value.full_name.length < 6) {
      errors.full_name =
        "Student Full Name must be at least 6 characters long.";
    }
    if (value.phone_number.length !== 10) {
      errors.phone_number = "Value must be a valid phone number.";
    }
    if (value.barcode.length !== 7) {
      errors.barcode =
        "Barcode must be 7 numbers long.";
    }
    if (Object.keys(errors).length > 0) {
      setEditError(errors);
      setDisabledEditButton(false);
      return false;
    } else {
      return true;
    }
  };

  function getChangedValues(selectedStudent) {
    const changedValues = {};
    for (const key in selectedStudent) {
      if (
        key !== "id" &&
        selectedStudent.hasOwnProperty(key) &&
        selectedStudent[key] !== initialStudent[key]
      ) {
        changedValues[key] = selectedStudent[key];
      }

      if (key === "id") {
        changedValues[key] = selectedStudent[key];
      }
    }
    return changedValues;
  }

  const editStudentDetails = async () => {
    const validityCheck = isValidEditStudent(selectedStudent);
    if (validityCheck === true) {
      setEditedInput(false);
      const data = getChangedValues(selectedStudent);
      const body = JSON.stringify(data);
      const response = await editStudent(body);
      if (response.success === true) {
        setSuccessMessage(response.message);
        setSuccess(true);
        fetchData();
        setEditmodal(false);
      } else {
        setFailed(true);
      }
    }
  };

  const downloadQRCode = async (student) => {
    try {
      const container = document.createElement('div');
      container.style.position = 'absolute';
      container.style.top = '-9999px';
      document.body.appendChild(container);
      const root = createRoot(container);

      root.render(<QRCard student={student} />);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      const canvas = await html2canvas(container, {
        scale: 3,
        useCORS: true,
      });

      const targetWidth = 1080;
      const targetHeight = (canvas.height / canvas.width) * targetWidth;
      const resizedCanvas = document.createElement('canvas');
      resizedCanvas.width = targetWidth;
      resizedCanvas.height = targetHeight;
      const context = resizedCanvas.getContext('2d');
      context.drawImage(canvas, 0, 0, targetWidth, targetHeight);
      const imgData = resizedCanvas.toDataURL('image/png');

      const downloadLink = document.createElement('a');
      downloadLink.href = imgData;
      downloadLink.download = `${student.registration_number}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      root.unmount();
      document.body.removeChild(container);
    } catch (error) {
    }
  };
  const studentNew = {
    registration_number: '12553789',
    full_name: 'Nuwan Kavishka Ganepola Ganepola Nuwan Kavishka Ganepola Ganepola',
    image_url: 'https://yathra.ardillalabs.cloud/api/uploads/414dcab9-f39e-447b-b465-ee3cd5e1214f-yathra-logo.png',
    barcode: '1001001'
  }
  return (
    <>
      <SecondaryHeader />
      <div className="mt--5 container-fluid">
        <Modal
          className="modal-dialog-centered modal-success"
          isOpen={isSuccess}
          toggle={() => setSuccess(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isSuccessMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setSuccess(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog modal-danger"
          isOpen={isFailed}
          toggle={() => setFailed(false)}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {isErrorMessage}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setFailed(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </Modal>
        <Card className="mb-4 shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">Add New Student</h3>
          </CardHeader>
          <Form className="ml-4 mb-4 mr-4">
            <Row>
              <Col md="12" lg="7">
                <FormGroup>
                  <Input
                    id="fullName"
                    placeholder="Full Name"
                    type="text"
                    value={isData.full_name}
                    onChange={handleInputChange}
                    name="full_name"
                  />
                  {isError.full_name && (
                    <p className="text-danger">{isError.full_name}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    id="barcode"
                    placeholder="Barcode"
                    type="text"
                    value={isData.barcode}
                    onChange={handleInputChange}
                    name="barcode"
                  />
                  {isError.barcode && (
                    <p className="text-danger">{isError.barcode}</p>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    id="phone_number"
                    placeholder="Phone Number"
                    type="text"
                    value={isData.phone_number}
                    onChange={handleInputChange}
                    name="phone_number"
                  />
                  {isError.phone_number && (
                    <p className="text-danger">{isError.phone_number}</p>
                  )}
                </FormGroup>
                <Row style={{ justifyContent: "space-between" }}>
                  <Col md="6">
                    <FormGroup>
                      <Dropdown
                        isOpen={gradeDropdownOpen}
                        toggle={() =>
                          setGradeDropdownOpen(!gradeDropdownOpen)
                        }
                      >
                        <DropdownToggle caret>
                          {selectedGradeForStudent ? selectedGradeForStudent.name : "Grade"}
                        </DropdownToggle>
                        {allGrades && allGrades.length > 0 ? (
                          <DropdownMenu>
                            {allGrades.map((grade) => (
                              <DropdownItem
                                key={grade.name}
                                value={grade.id}
                                onClick={() => [
                                  setIsData({
                                    ...isData,
                                    gradeId: grade.id,
                                  }),
                                  setSelectedTeacher(null),
                                  setSelectedSubject(null),
                                  setSelectedGradeForStudent(grade),
                                  getTeachers(grade.id),
                                  setEnrollClsError('')
                                ]}
                              >
                                {grade.name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        ) : null}
                      </Dropdown>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Dropdown
                        isOpen={teacherDropdownOpen}
                        toggle={() =>
                          setTeacherDropdownOpen(!teacherDropdownOpen)
                        }
                      >
                        <DropdownToggle caret>
                          {selectedTeacher ? selectedTeacher.full_name : "Teacher"}
                        </DropdownToggle>
                        {allTeachersByGrade && allTeachersByGrade.length > 0 ? (
                          <DropdownMenu>
                            {allTeachersByGrade && allTeachersByGrade.map((teacher) => (
                              <DropdownItem
                                key={teacher.full_name}
                                value={teacher.id}
                                onClick={() => {
                                  setSelectedTeacher(teacher);
                                  setSelectedSubject(null);
                                  getSubjects(selectedGradeForStudent.id, teacher.id);
                                  setTeacherDropdownOpen(false);
                                  setEnrollClsError('')
                                }}
                              >
                                {teacher.full_name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        ) : null}
                      </Dropdown>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Dropdown
                        isOpen={subjectDropdownOpen}
                        toggle={() =>
                          setSubjectDropdownOpen(!subjectDropdownOpen)
                        }
                      >
                        <DropdownToggle caret>
                          {selectedSubject ? selectedSubject.name : "Subject"}
                        </DropdownToggle>
                        {allSubjects && allSubjects.length > 0 ? (
                          <DropdownMenu>
                            {allSubjects && allSubjects.map((subject) => (
                              <DropdownItem
                                key={subject.name}
                                value={subject.id}
                                onClick={() => {
                                  setSelectedSubject(subject);
                                  setSubjectDropdownOpen(false);
                                  setEnrollClsError('')
                                }}
                              >
                                {subject.name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        ) : null}
                      </Dropdown>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Button color="primary" style={{ height: "100%" }} onClick={() => addClass()}>✓</Button>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="text-danger mb-2">{enrollClsError}</div>
                <div>
                  <Table className="align-items-center" responsive>
                    <tbody>
                      {selectedClasses?.map((selectedClass, index) => (
                        <tr key={index}>
                          <td>{selectedClass.teacher}</td>
                          <td>{selectedClass.subject} - {selectedClass.grade}  </td>
                          <td><span style={{ cursor: "pointer", color: "red" }} onClick={() => removeEnrolledClass(index)}>X</span></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <Button
                  color="primary"
                  type="button"
                  onClick={() => {
                    insertNewStudent();
                  }}
                  disabled={
                    !isData.full_name ||
                    !isData.phone_number ||
                    isInsertButtonDisabled
                  }
                >
                  Insert
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
        {isLoadingStudents ? (
          <Card style={{}}>
            <Spinner className="m-10">Loading...</Spinner>
          </Card>
        ) : !isAllStudents ? (
          <Card className="text-center" style={{ padding: "1rem" }}>
            <img src={require("../../assets/img/brand/nodata.png")} className="noDataImage" />
          </Card>
        ) : (
          <Card className="shadow" style={{ padding: "1rem" }}>
            <CardHeader className="border-0">
              <Row className="mb-4">
                <Col>
                  <h3 className="mb-0">Students</h3>
                </Col>
              </Row>
              <Row className="filterClass">
                <Col md="8" lg="4">
                  <FormGroup>
                    <InputGroup className="mb-3">
                      <Input
                        type="text"
                        placeholder="Student Name"
                        value={searchQuery}
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col xs="12" md="4" lg="4" style={{ paddingLeft: "15px" }}>
                  <FormGroup>
                    <Dropdown
                      isOpen={gradeFilterDropdownOpen}
                      toggle={() => setGradeFilterDropdownOpen(!gradeFilterDropdownOpen)}
                    >
                      <DropdownToggle caret>
                        {selectedGradeFilter ? selectedGradeFilter.name : "Grade"}
                      </DropdownToggle>
                      <DropdownMenu
                        style={{ maxHeight: 250, overflowY: "auto" }}
                      >
                        {allGrades?.map((grade) => (
                          <DropdownItem
                            key={grade.id}
                            value={grade.id}
                            onClick={() => [
                              setSelectedGradeFilter(grade),
                              setSelectedTeacherFilter(null),
                              setSelectedSubjectFilter(null),
                              getTeachersToFilter(grade.id),
                            ]}
                          >
                            {grade.name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                </Col>
                <Col xs="12" md="6" lg="4" style={{ paddingLeft: "15px" }}>
                  <FormGroup>
                    <Dropdown
                      isOpen={teacherFilterDropdownOpen}
                      toggle={() =>
                        setTeacherFilterDropdownOpen(!teacherFilterDropdownOpen)
                      }
                    >
                      <DropdownToggle caret>
                        {selectedTeacherFilter
                          ? selectedTeacherFilter.full_name
                          : "Teacher"}
                      </DropdownToggle>
                      <DropdownMenu
                        style={{ maxHeight: 250, overflowY: "auto" }}
                      >
                        {allTeachers?.map((teacher) => (
                          <DropdownItem
                            key={teacher.id}
                            value={teacher.id}
                            onClick={() => [
                              setSelectedTeacherFilter(teacher),
                              setSelectedSubjectFilter(null),
                              getSubjectsToFilter(selectedGradeFilter.id, teacher.id)
                            ]
                            }
                          >
                            {teacher.full_name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                </Col>
                <Col xs="12" md="6" lg="4" style={{ paddingLeft: "15px" }}>
                  <FormGroup>
                    <Dropdown
                      isOpen={subjectFilterDropdownOpen}
                      toggle={() =>
                        setSubjectFilterDropdownOpen(!subjectFilterDropdownOpen)
                      }
                    >
                      <DropdownToggle caret>
                        {selectedSubjectFilter
                          ? selectedSubjectFilter.name
                          : "Subject"}
                      </DropdownToggle>
                      <DropdownMenu
                        style={{ maxHeight: 250, overflowY: "auto" }}
                      >
                        {allActiveSubjects?.map((subject) => (
                          <DropdownItem
                            key={subject.id}
                            value={subject.id}
                            onClick={() =>
                              setSelectedSubjectFilter(subject)
                            }
                          >
                            {subject.name}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                </Col>

                <Col className="filterMobile">
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => filterStudents(selectedGradeFilter?.id, selectedSubjectFilter?.id, selectedTeacherFilter?.id)}
                  >
                    Filter
                  </Button>
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => resetFilter()}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Reg. No</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Barcode</th>
                  <th scope="col">Contact Number</th>
                  <th scope="col">Status</th>
                  <th colSpan="3" className="actionTh">Actions</th>
                </tr>
              </thead>
              <tbody>
                {allStudents?.map((student, index) => (
                  <tr key={index}>
                    <td>{student.registration_number}</td>
                    <td>{student.full_name}</td>
                    <td>{student.barcode}</td>
                    <td>{student.phone_number}</td>
                    <td>{student.status}</td>
                    <td className="actionTd" style={{ textAlign: "center" }}>
                      <Button
                        color="secondary"
                        type="button"
                        tag={Link}
                        to={`/admin/students/${student.id}`}
                      >
                        Class Details
                      </Button>
                      <Button color="secondary"
                        type="button"
                        id="downloadId"
                        onClick={() => downloadQRCode(student)}>
                        <LiaQrcodeSolid />
                      </Button>
                      <Button
                        color="primary"
                        type="button"
                        id="editStudent"
                        onClick={() => handleEditClick(student)}
                      >
                        <BiEdit />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div style={{ paddingTop: "10px" }}>
              {pageCount > 1 ?
                <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                  currentStart={currentStart}
                  setCurrentStart={setCurrentStart}></Paginations>
                : null}
            </div>
          </Card>
        )}
        {/* Edit student */}
        <Modal
          className="modal-dialog-centered"
          isOpen={editModal}
          toggle={() => [setEditmodal(false)]}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Edit Student
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => [
                setEditmodal(false),
                setDisabledEditButton(true),
                setEditError({
                  full_name: "",
                  phone_number: "",
                  password: "",
                }),
              ]}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <Form>
              <Row>
                <Col>
                <FormGroup>
                <Input
                      placeholder="Barcode"
                      type="text"
                      value={
                        selectedStudent?.barcode
                          ? selectedStudent?.barcode
                          : ""
                      }
                      disabled
                    />
                </FormGroup>
                  <FormGroup>
                    <Input
                      id="editFullName"
                      placeholder="Full Name"
                      type="text"
                      value={
                        selectedStudent?.full_name
                          ? selectedStudent?.full_name
                          : ""
                      }
                      name="full_name"
                      onChange={(e) => {
                        setSelectedStudent({
                          ...selectedStudent,
                          full_name: e.target.value,
                        });
                        setEditedInput(true);
                        setDisabledEditButton(false);
                        setEditError({ full_name: "" });
                      }}
                    />
                    {isEditError.full_name && (
                      <p className="text-danger">{isEditError.full_name}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Input
                      id="editPhoneNumber"
                      placeholder="Phone Number"
                      type="text"
                      value={
                        selectedStudent?.phone_number
                          ? selectedStudent?.phone_number
                          : ""
                      }
                      name="phone_number"
                      onChange={(e) => {
                        setSelectedStudent({
                          ...selectedStudent,
                          phone_number: e.target.value,
                        });
                        setEditedInput(true);
                        setDisabledEditButton(false);
                        setEditError({ phone_number: "" });
                      }}
                    />
                    {isEditError.phone_number && (
                      <p className="text-danger">{isEditError.phone_number}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Dropdown
                      isOpen={statusDropdownOpen}
                      toggle={() => setStatusDropdownOpen(!statusDropdownOpen)}
                    >
                      <DropdownToggle caret>
                        {selectedStudent
                          ? selectedStudent.status
                          : "Select a Status"}
                      </DropdownToggle>
                      <DropdownMenu>
                        {Object.entries(Status).map(([key, value]) => (
                          <DropdownItem
                            key={value}
                            value={value}
                            onClick={() => [
                              setSelectedStudent({
                                ...selectedStudent,
                                status: value,
                              }),
                              setEditedInput(true),
                              setDisabledEditButton(false),
                            ]}
                          >
                            {value}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>

                  <Button
                    color="primary"
                    type="button"
                    onClick={() => editStudentDetails()}
                    disabled={!isEditedInput || isDisabledEditButton}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </div >
    </>
  );
};

export default Students;
