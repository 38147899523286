import React, { useState, useEffect, useRef } from "react";
import { Card, CardHeader, Form, Row, Col, FormGroup, Input, Button, Table } from "reactstrap";
import StudentImage from "../../../assets/img/brand/blank_profile_pic.png";
import { updateStudentByClassAssistant, getStudentByClassAssistants } from "actions/ClassAssistantLayout";
import { createStudentByClassAssistant } from "actions/ClassAssistantLayout";
import { useReactToPrint } from 'react-to-print';
import TemporaryReceipt from "components/Reports/TemporaryReceipt";
import '../../../assets/css/temporary-card-styles.css';
import { filterStudentListByAssistant } from "actions/ClassAssistantLayout";
import '../../../assets/css/profile-card.css'; 

const ClassAssistantStudent = () => {
    const receiptRef = useRef();
    const [isData, setData] = useState({});
    const [isError, setError] = useState({});
    const [allClasses, setAllClasses] = useState(null);
    const barcodeInputRef = useRef(null);
    const [barcodeInput, setBarcodeInput] = useState('');
    const [barcodeOnly, setBarcodeOnly] = useState(false);
    const [regNumber, setRegNumber] = useState(null);
    const [isReceivedData, setReceivedData] = useState(false);

    const handleInputChange = (event) => {
        setError({ ...isError, [event.target.name]: "" });
        if (event.target.name === 'admission_fee') {
            setData({ ...isData, [event.target.name]: Number(event.target.value) });
        } else {
            setData({ ...isData, [event.target.name]: event.target.value });
        }
    };

    const getStudent = async (e) => {
        setReceivedData(false);
        let barcode = e.target.value;
        if (barcode.length <= 7) {
            setBarcodeInput(e.target.value);
        } else
            if (barcode.length > 7) {
                barcode = barcode.slice(7);
                setBarcodeInput(barcode);
            }
        handleInputChange(e);
        try {
            if (barcode.length === 7) {
                const response = await getStudentByClassAssistants(barcode);
                if (response.success === true) {
                    setData(response.students);
                    setAllClasses(response.students.class_users);
                    setReceivedData(true);
                    setBarcodeOnly(false);
                } if (response.success === false) {
                    setData({ barcode: barcode });
                    setBarcodeOnly(true);
                    setAllClasses(null);
                }
            }
        } catch (error) {
            setData({});
            setAllClasses(null);
        }
    };

    const resetStudent = () => {
        setReceivedData(false);
        setData({});
        setAllClasses(null);
        setBarcodeInput('');
        if (barcodeInputRef.current) {
            barcodeInputRef.current.focus();
        }
    };

    const insertNewStudent = async () => {
        try {
            if (barcodeOnly) {
                const response = await createStudentByClassAssistant(isData);
                if (response.success === true) {
                    setData(response.student);
                    setBarcodeOnly(false);
                    setRegNumber(response.student.registration_number);
                }
            } else {
                const response = await updateStudentByClassAssistant(isData);
            }
        } catch (error) {
        }
    };

    let barcodeString = ''; // To store the captured barcode data

    const handleKeyDown = async (event) => {
        // if (event.key === 'Enter') {
        //     const response = await getStudentByClassAssistants(barcodeString);
        //     if (response.success === true) {
        //         setData(response.students);
        //         setAllClasses(response.students.class_users);
        //         setBarcodeOnly(false);
        //     } if (response.success === false) {
        //         setData({ barcode: barcodeString });
        //         setBarcodeOnly(true);
        //         setAllClasses(null);
        //     }
        //     setBarcodeInput(barcodeString);
        //     barcodeString = '';
        // } else {
        const barcodePattern = /^[0-9]$/;
        if (barcodePattern.test(event.key)) {
            barcodeString += event.key;
            if (barcodeString.length === 7) {
                // Process the full barcode data here
                barcodeInputRef.current.value = barcodeString;
            }
            // }
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);


    const handlePrint = useReactToPrint({
        content: () => receiptRef.current,
    });

    const [searchItem, setSearchItem] = useState('')
    const [filteredUsers, setFilteredUsers] = useState(null)

    const handleInputChange1 = async (e) => {
        const searchTerm = e.target.value;
        setSearchItem(searchTerm);
        if (searchTerm.length >= 3) {
            const response = await filterStudentListByAssistant(searchTerm);

            const filteredItems = response.data.data.filter((user) =>
                user.full_name.toLowerCase().includes(searchTerm.toLowerCase())
            );

            setFilteredUsers(filteredItems);
        }
    }

    const getStudentBySeacrh = async (student) => {
        try {
            const response = await getStudentByClassAssistants(student);
            if (response.success === true) {
                setFilteredUsers(null);
                setSearchItem('')
                setBarcodeInput(student);
                setReceivedData(true);
                setData(response.students);
                setAllClasses(response.students.class_users);
                setBarcodeOnly(false);
            } if (response.success === false) {
                setBarcodeOnly(true);
                setAllClasses(null);
            }
        } catch (error) {

        }
    }

    const [searchbar, setSearchBar] = useState(false);

    return (
        <>
            <div className="mt--3 container-fluid assistant-container">
                <Card className="mt-4 mb-4 shadow">
                    <CardHeader className="border-0 assistantStudentHeader">
                        <div className="headerLeft" style={{ display: 'flex', alignItems: 'center' }}>
                            <h3 className="mb-0 mr-2">Student</h3>
                            <i class="fa-solid fa-magnifying-glass" style={{ cursor: 'pointer' }} onClick={() => setSearchBar(!searchbar)}></i>
                        </div>
                        <div className="headerRight">
                            <Button type="button" onClick={handlePrint} color="primary">Temporary Card</Button>
                            <Button type="button" onClick={insertNewStudent} color="primary">Save</Button>
                            <Button type="button" onClick={resetStudent} color="default">Reset</Button>
                        </div>
                    </CardHeader>
                    <Form className="ml-4 mb-4 mr-4">
                        <Row className="studentInsertUpper">
                            <Col md="12" lg="8">
                                {searchbar ?
                                    <Row>
                                        <Col sm="6">
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    value={searchItem}
                                                    onChange={handleInputChange1}
                                                    placeholder="Search..."
                                                    style={{ borderBottomLeftRadius: searchItem !== '' ? '0px' : '', borderBottomRightRadius: searchItem !== '' ? '0px' : '' }}
                                                />
                                                {searchItem && (
                                                    <ul style={{ position: 'absolute', width: '94%', listStyleType: 'none', padding: '0', border: '1px solid #ddd', zIndex: 10, backgroundColor: 'white', display: filteredUsers === null ? 'none' : '' }}>
                                                        {filteredUsers?.map((option, index) => (
                                                            <li key={index} style={{ padding: '8px', borderBottom: '1px solid #ddd', cursor: 'pointer' }} onClick={() => getStudentBySeacrh(option.barcode)}>
                                                                {option.full_name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    : null}
                                <Row>
                                    <Col sm="6" md="6" lg="6">
                                        <FormGroup>
                                            <Input
                                                id="barcode"
                                                placeholder="Barcode"
                                                type="text"
                                                value={barcodeInput}
                                                onChange={getStudent}
                                                name="barcode"
                                                ref={barcodeInputRef}
                                                autoFocus
                                            />
                                            {isError.barcode && (
                                                <p className="text-danger">{isError.barcode}</p>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6" md="6" lg="6">
                                        <FormGroup>
                                            <Input
                                                id="registration_number"
                                                placeholder="Registration Number"
                                                type="text"
                                                value={isData.registration_number || ''}
                                                onChange={handleInputChange}
                                                name="registration_number"
                                            />
                                            {isError.registration_number && (
                                                <p className="text-danger">{isError.registration_number}</p>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Input
                                        id="full_name"
                                        placeholder="Full Name"
                                        type="text"
                                        value={isData.full_name || ''}
                                        onChange={handleInputChange}
                                        name="full_name"
                                    />
                                    {isError.full_name && (
                                        <p className="text-danger">{isError.full_name}</p>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        id="address"
                                        placeholder="Address"
                                        type="text"
                                        value={isData.address || ''}
                                        onChange={handleInputChange}
                                        name="address"
                                    />
                                    {isError.address && (
                                        <p className="text-danger">{isError.address}</p>
                                    )}
                                </FormGroup>
                                <Row>
                                    <Col sm="6" md="6" lg="6">
                                        <FormGroup>
                                            <Input
                                                id="phone_number"
                                                placeholder="Phone Number"
                                                type="text"
                                                value={isData.phone_number || ''}
                                                onChange={handleInputChange}
                                                name="phone_number"
                                            />
                                            {isError.phone_number && (
                                                <p className="text-danger">{isError.phone_number}</p>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6" md="6" lg="6">
                                        <FormGroup>
                                            <Input
                                                id="whatsapp_number"
                                                placeholder="WhatsApp Number"
                                                type="text"
                                                value={isData.whatsapp_number || ''}
                                                onChange={handleInputChange}
                                                name="whatsapp_number"
                                            />
                                            {isError.whatsapp_number && (
                                                <p className="text-danger">{isError.whatsapp_number}</p>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Input
                                        id="remark"
                                        placeholder="Remark"
                                        type="text"
                                        value={isData.remark || ''}
                                        onChange={handleInputChange}
                                        name="remark"
                                    />
                                    {isError.remark && (
                                        <p className="text-danger">{isError.remark}</p>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col md="12" lg="4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {isReceivedData === true?
                                <div className="profile-card">
                                    <div className="profile-card-header">
                                        <i className="icon-back"></i>
                                        <i className="icon-menu"></i>
                                    </div>
                                    <div className="profile-img">
                                        <img
                                            src={StudentImage}
                                            alt="profile"
                                        />
                                    </div>
                                    <h2><i class="fa-solid fa-circle-user"></i> {isData.full_name}</h2>
                                    <p style={{fontSize: "12px"}}><i class="fa-solid fa-registered"></i> {isData.registration_number}</p>
                                    {isData.phone_number !== null? <p><i class="fa-solid fa-phone-volume"></i> {isData.phone_number}</p>  : null}                                 
                                    <div className="action-buttons">
                                        <Button type="button" color="primary" onClick={handlePrint} className="btn-message">Temporary</Button>
                                        <Button type="button" color="default" className="btn-subscribe">Late</Button>
                                    </div>
                                </div>
                                : null }
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" md="12" lg="4">
                                <Row>
                                    <Col sm="6" md="6" lg="12">
                                        <FormGroup>
                                            <Input
                                                id="school"
                                                placeholder="School"
                                                type="text"
                                                value={isData.school || ''}
                                                onChange={handleInputChange}
                                                name="school"
                                            />
                                            {isError.school && (
                                                <p className="text-danger">{isError.school}</p>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6" md="6" lg="12">
                                        <FormGroup>
                                            <Input
                                                id="admission_fee"
                                                placeholder="Admission Fee"
                                                type="number"
                                                value={isData.admission_fee || ''}
                                                onChange={handleInputChange}
                                                name="admission_fee"
                                            />
                                            {isError.admission_fee && (
                                                <p className="text-danger">{isError.admission_fee}</p>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm="12" md="12" lg="8">
                                {allClasses?.length > 0 ?
                                    <Table className="align-items-center" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Teacher</th>
                                                <th scope="col">Subject</th>
                                                <th scope="col">Grade</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allClasses?.map((classUser, index) => (
                                                <tr key={index}>
                                                    <td>{classUser.teacher_class.teacher.full_name}</td>
                                                    <td>{classUser.teacher_class.subject.name}</td>
                                                    <td>{classUser.teacher_class.grade.name}</td>
                                                </tr>))}
                                        </tbody>
                                    </Table>: null}
                            </Col>
                        </Row>
                    </Form>
                </Card>
                <div style={{ display: "none" }}>
                    <TemporaryReceipt ref={receiptRef} allDetails={[isData.barcode, isData.full_name]} />
                </div>
            </div>
        </>
    );
};

export default ClassAssistantStudent;